<template>
  <div>
    <div v-if="users" class="position-table">
      <md-table v-model="users">
        <md-table-row slot="md-table-row" slot-scope="{ item }">
          <md-table-cell md-label="№">
            <span class="text ws-nowrap">{{ item.id }}</span>
          </md-table-cell>
          <md-table-cell md-label="Дата">
            <span class="text ws-nowrap">{{ item.date }}</span>
          </md-table-cell>
          <md-table-cell md-label="Описание жалобы">
            <span class="text">{{ item.text }}</span>
          </md-table-cell>
          <md-table-cell md-label="Возможность">
            <div class="edit-and-delete-block d-flex">
              <md-button class="md-simple md-primary"> Принять </md-button>
              <md-button class="md-simple md-primary"> Отклонить </md-button>
            </div>
          </md-table-cell>
        </md-table-row>
      </md-table>
      <Pagination
        :current-page="pagination.current_page"
        :last-page="pagination.last_page"
      />
    </div>
  </div>
</template>

<script>
import Pagination from "@/components/ui/Pagination";

export default {
  components: {
    Pagination,
  },
  data() {
    return {
      pagination: {
        current_page: 1,
        last_page: 2,
      },
      users: [
        {
          id: 1,
          date: "22.09.2023",
          text: "жалоба на процесс игры",
        },
        {
          id: 2,
          date: "22.09.2023",
          text: "длинный текст, в котором большая жалоба на игру, где все виноваты, кроме того, кто пишет, а особенно виноват тот, о ком пишут, прошу разберитесь, а то не получается самому разобраться",
        },
        {
          id: 3,
          date: "22.09.2023",
          text: "мне не повезло с раздачей",
        },
        {
          id: 12,
          date: "22.09.2023",
          text: "жалоба на процесс игры",
        },
        {
          id: 22,
          date: "22.09.2023",
          text: "длинный текст, в котором большая жалоба на игру, где все виноваты, кроме того, кто пишет, а особенно виноват тот, о ком пишут, прошу разберитесь, а то не получается самому разобраться",
        },
        {
          id: 32,
          date: "22.09.2023",
          text: "мне не повезло с раздачей",
        },
        {
          id: 13,
          date: "22.09.2023",
          text: "жалоба на процесс игры",
        },
        {
          id: 23,
          date: "22.09.2023",
          text: "длинный текст, в котором большая жалоба на игру, где все виноваты, кроме того, кто пишет, а особенно виноват тот, о ком пишут, прошу разберитесь, а то не получается самому разобраться",
        },
        {
          id: 33,
          date: "22.09.2023",
          text: "мне не повезло с раздачей",
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.text {
  word-break: break-word;
}

.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
}

.pages {
  margin-right: 20px;
  margin-left: 20px;
}
</style>
